



































import { Component, Mixins } from 'vue-property-decorator';

import { Map } from 'client-website-ts-library/components';

import { ListingFilter } from 'client-website-ts-library/filters';
import { ListingStatus, ListingCategory, Office, MapSettings, MapMarker, Listing } from 'client-website-ts-library/types';

import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';

import Listings from '../components/Listings.vue';
import SideBySide from '../components/Layout/SideBySide.vue';
import ItemCycler from '../components/Cycler/ItemCycler.vue';
import VideoBanner from '../components/VideoBanner.vue';

import { ItemCyclerSlide, ItemCyclerImageSlide } from '../app_code/ItemCycler';

@Component({
  components: {
    Listings,
    SideBySide,
    ItemCycler,
    VideoBanner,
    Map,
  },
})
export default class Home extends Mixins(View) {
  private office: Office | null = null;

  private mapSettings: MapSettings | null = null;

  private listingFilter = new ListingFilter({
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    Categories: [ListingCategory.ResidentialSale, ListingCategory.ResidentialLand, ListingCategory.Rural, ListingCategory.Commercial, ListingCategory.CommercialLand, ListingCategory.Business],
    IsHighlightListing: true,
    PageSize: 4,
    Page: 1,
  });

  private listingMapFilter = new ListingFilter({
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    Categories: [ListingCategory.ResidentialSale, ListingCategory.ResidentialLand, ListingCategory.Rural, ListingCategory.Commercial, ListingCategory.CommercialLand, ListingCategory.Business],
    PageSize: 400,
  });

  private cyclerImages: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/1', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/2', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/3', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
  ];

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });
    console.log(11111111111111111111);
    const markers: MapMarker[] = [];

    API.Listings.Search(this.listingMapFilter).then((data) => {
      data.Items.forEach((listing) => {
        markers!.push(
          new MapMarker({
            Coordinate: {
              Lat: listing.Address.Coordinates.Lat,
              Lon: listing.Address.Coordinates.Lon,
            },
            Content: `<div class='listing-map__preview'>
                <a class='listing-preview' href='/listings/${listing.Id}')'>
                  ${listing.Images.length ? `<div class='listing-preview__image' style='background-image:url(${listing.Images[0].Thumbs.Url})'></div>` : ''}
                  <div class='listing-preview__details'>
                    <h5 class='listing-preview__address'>${listing.Address.MicroAddress}</h5>
                    <h6 class='listing-preview__price'>${listing.Price.Price}</h6>
                    <h6>View More</h6>
                  </div>
                </a>
              </div>`,
          }),
        );
      });
    });

    console.log(22222222222222);
    console.log(markers?.length);

    this.mapSettings = new MapSettings({
      Markers: markers!,
      Interactive: false,
      GreedyZoom: true,
      Zoom: 10,
      Center: {
        Lat: -17.6399586,
        Lon: 168.3040507,
      },
    });
  }
}
